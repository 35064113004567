import React, { useState, useEffect, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { gapi } from 'gapi-script';
import { loadAuth2WithProps } from 'gapi-script';
import { useHistory, useLocation, } from "react-router-dom";
import { FileDrop } from 'react-file-drop';
import smartcrop from 'smartcrop'
import imageCompression from 'browser-image-compression';
import './Calendars.css'

import {
  getConnectedCalendars, getAvailableCalendars,
} from '../../../../reducers/CalendarsReducer'

import { setUserCredentials, } from '../../../../reducers/UserReducer'

import API from '../../../../rest/api'
import gconfig from '../../../../gconfig'
import { allowedFileTypes } from '../../../../helpers/constants'
import { getFormattedDate } from '../../../../helpers/dateFormatter';

function Calendars(props) {
  const dispatch = useDispatch()

  const history = useHistory();
  const location = useLocation();

  const userInfo = useSelector(state => state.user.info)

  const [connectionLoading, setConnectionLoading] = useState(false)
  const [disconnectionLoading, setDisconnectionLoading] = useState(false)
  const [inviteLink, setInviteLink] = useState(null)
  const [copied, setCopyState] = useState(false)
  const [copiedToken, setCopyTokenState] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(null)
  const [rotateLoading, setRotateLoading] = useState(null)
  const [newLinkPin, setNewLinkPin] = useState('')
  const [pinLoading, setPinLoading] = useState(false)
  const [newPinError, setNewPinError] = useState(null)
  const [selectedCalendar, setSelectedCalendar] = useState(null)
  const [uploadButtonVisible, setUploadButtonVisibility] = useState(true)
  const [uploadContentVisible, setUploadContentVisibility] = useState(true)
  const [uploadedImage, setPreview] = useState('')
  const [uploadError, setUploadError] = useState('')
  const [pinRequired, setPinRequired] = useState(true)

  useEffect(() => {
    if (location && location.state) {
      setSelectedCalendar(location.state.selectedCalendar)
      setInviteLink(location.state.inviteLink)

      if (location.state.pin !== undefined) {
        setNewLinkPin(location.state.pin)
      }

      if (location.state.image !== undefined) {
        setPreview(location.state.image)
      }

      if (location.state.hasPin !== undefined) {
        setPinRequired(location.state.hasPin)
      }
    } else {
      history.push("/user/dashboard");
    }
  }, [location])

  const getAllCalendars = async () => {
    const connected = await dispatch(getConnectedCalendars())

    await dispatch(getAvailableCalendars(connected))
  }

  const handleSaveCredentials = async () => {
    let auth2 = await loadAuth2WithProps(gconfig);

    const currentUser = await auth2.currentUser.get()

    const payload = {
      credentials: currentUser.tc,
      calendar_id: location.state.calendarId,
      calendar_user_id: userInfo.email,
      object_id: location.state.objectId,
      image: uploadedImage,
      hasPin: pinRequired,
      // ...offlineParams,
    }

    console.log('payload', payload)

    await API.connectCalendar(payload)

    history.push("/user/dashboard");
  }

  async function handleUpdateCalendar() {
    try {
      setUpdateLoading(true)

      const payload = {
        calendar_id: location.state.calendarId,
        image: uploadedImage,
        hasPin: pinRequired,
      }

      await API.updateCalendar(payload)

      await getAllCalendars()

      console.log('updated')

      history.push("/user/dashboard");
    } catch (error) {
      console.log('error - handleUpdateCalendar', error)
    } finally {
      setUpdateLoading(false)
    }
  }

  async function handleRotateURLCalendar() {
    try {
      setRotateLoading(true)

      const payload = {
        calendar_id: location.state.calendarId,
      }

      const { object_id } = await API.generateNewURL(payload)

      setInviteLink(`${document.location.origin}/j/${object_id}`)

      await getAllCalendars()

      console.log('rotated')

      // history.push("/user/dashboard");
    } catch (error) {
      console.log('error - handleUpdateCalendar', error)
    } finally {
      setRotateLoading(false)
    }
  }

  async function handleConnectCalendar() {
    try {
      setConnectionLoading(true)

      // if (!uploadedImage) {
      //   setUploadError('Photo is required')

      //   return
      // }

      await handleSaveCredentials()

      setConnectionLoading(false)

      getAllCalendars()
    } catch (error) {
      if (error.message !== 'No access token' && error.message !== 'Not found') return

      dispatch(setUserCredentials(null))

      let auth2 = await loadAuth2WithProps(gconfig);

      const currentUser = await auth2.currentUser.get()

      let offlineParams = await currentUser.grantOfflineAccess({ access_type: 'offline', })

      await API.sendGoogleAuthCode({ ...offlineParams, calendar_id: userInfo.email })

      dispatch(setUserCredentials(currentUser.tc))

      await handleSaveCredentials()

      getAllCalendars()
    } finally {
      setConnectionLoading(false)
    }
  }

  async function handleDisconnectCalendar() {
    try {
      setDisconnectionLoading(true)

      await API.disconnectCalendar({ calendar_id: location.state.calendarId, calendar_user_id: userInfo.email })

      const connected = await dispatch(getConnectedCalendars())
      await dispatch(getAvailableCalendars(connected))

      history.push('/user/dashboard')
    } catch (error) {
      console.log('api error', error)
    } finally {
      setDisconnectionLoading(false)
    }
  }

  const handleManualUpload = async (e) => {
    try {
      const uploadedFile = e.target.files[0]

      const base64 = await convertToBase64(uploadedFile)

      // setUploadButtonVisibility(false)
      setUploadContentVisibility(false)

      setPreview(base64)

      setUploadError('')

      // console.log('base64 ----------->', base64)
    } catch (error) {
      console.log('upload error', error)
    }
  }

  const handleDropUpload = async (files) => {
    try {
      const uploadedFile = files[0]

      if (!allowedFileTypes.includes(uploadedFile.type)) {
        throw new Error('Inappropriate file type')
      }

      // const canvas = convertToCanvas(uploadedFile)

      // const cropOptions = await smartcrop.crop(canvas, { width: 100, height: 100 })

      // console.log('cropOptions', cropOptions)

      // const croppedCanvas = convertToCanvas(uploadedFile, cropOptions.topCrop)

      // console.log('croppedCanvas', croppedCanvas.toDataURL())

      // const base64 = croppedCanvas.toDataURL()

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 350,
      }

      const compressed = await imageCompression(uploadedFile, options)

      const base64 = await convertToBase64(compressed)

      const imageSize = ((base64.length * (3 / 4)) - 1) / 1000

      console.log('base64 size in KB', imageSize)

      if (imageSize > 140) {
        throw new Error('Image file size is too large')
      }

      setUploadContentVisibility(false)

      setPreview(base64)

      setUploadError('')

      // console.log('base64 ----------->', base64)
    } catch (error) {
      setUploadError(error.message)

      console.log('upload error', error)
    }
  }

  function convertToBase64(uploadedFile) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();

      reader.onloadend = function () {
        resolve(reader.result)
      }

      reader.readAsDataURL(uploadedFile);
    })
  }

  function convertToCanvas(uploadedFile, cropOptions) {
    const c = document.createElement('canvas')

    const ctx = c.getContext('2d');;

    let base_image = new Image();
    base_image.src = 'img/base.png';
    base_image.width = 250
    base_image.height = 300
    base_image.onload = function () {
      ctx.drawImage(uploadedFile, cropOptions ? cropOptions.x : 0, cropOptions ? cropOptions.y : 0, cropOptions ? cropOptions.width : base_image.width, cropOptions ? cropOptions.height : base_image.height);
    }

    return cropOptions ? c : base_image
  }

  function onCopy() {
    const el = document.createElement('textarea');
    el.value = inviteLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setCopyState(true)

    setTimeout(() => {
      setCopyState(false)
    }, 1000)
  }

  function onCopyToken() {
    const el = document.createElement('textarea');
    el.value = location.state.objectId;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setCopyTokenState(true)

    setTimeout(() => {
      setCopyTokenState(false)
    }, 1000)
  }

  const onSetPin = async () => {
    if (!location.state.objectId) return

    try {
      if (newLinkPin.length === 0) {
        throw new Error('Pin code cannot be empty')
      }

      setPinLoading(true)

      const payload = {
        object_id: location.state.objectId,
        pin: newLinkPin
      }

      await API.setLinkPin(payload)
    } catch (error) {
      setNewPinError(error.message)
    } finally {
      setPinLoading(false)
    }
  }

  return (
    <div className='settings-container'>
      <div id='croppie' />
      <div className='settings-content'>
        <div className='settings-content-top'>
          <div className='service-block'>
            <h3>
              {(location.state && location.state.heading) ? location.state.heading : 'Connecting to'} {selectedCalendar ? selectedCalendar.summary : ''}
            </h3>
            <div className="invite-container">
              <div className='pin-input-container'>
                <div className='url-heading'>
                  Nag URL
                </div>
                <input
                  className="set-url-input"
                  value={inviteLink}
                  onFocus={() => setNewPinError(null)}
                  onChange={(e) => { }}
                  placeholder='Enter new URL'
                >
                </input>
                <button
                  className={'apply-button'}
                  onClick={onCopy}
                >
                  {copied ? 'Copied!' : 'Copy'}
                </button>
              </div>

              <div className='pin-input-container'>
                <div className='url-heading'>
                  Nag Token
                </div>
                <input
                  className="set-token-input"
                  value={location.state.objectId}
                  onFocus={() => setNewPinError(null)}
                  onChange={(e) => { }}
                  placeholder='Enter new token'
                >
                </input>
                <button
                  className={'apply-button'}
                  onClick={onCopyToken}
                >
                  {copiedToken ? 'Copied!' : 'Copy'}
                </button>
              </div>

              <div className='pin-checkbox-container'>
                <input
                  type='checkbox'
                  checked={pinRequired}
                  onChange={(e) => setPinRequired(e.target.checked)}
                />
                <div className='pin-checkbox-heading'>
                  Protect with PIN Code
                </div>
              </div>

              <div className='pin-input-container'>
                <div className='pin-heading'>
                  PIN Code
                </div>
                <input
                  className="set-pin-input"
                  value={newLinkPin}
                  onFocus={() => setNewPinError(null)}
                  onChange={(e) => setNewLinkPin(e.target.value)}
                  placeholder='Enter PIN'
                >
                </input>
                <button
                  className={`apply-button ${pinLoading ? 'loading' : ''}`}
                  onClick={onSetPin}
                >
                  Set PIN
                </button>
              </div>

              {newPinError && (
                <div className='pin-error'>
                  {newPinError}
                </div>
              )}
            </div>

            <div className='upload-container mobile' style={{ backgroundImage: `url(${uploadedImage})` }}>
              <FileDrop
                onFrameDragEnter={(event) => {
                  // setUploadButtonVisibility(false)
                }}
                onFrameDragLeave={(event) => { }}
                onFrameDrop={(event) => { }}
                onDragOver={(event) => { }}
                onDragLeave={(event) => setUploadButtonVisibility(true)}
                onDrop={handleDropUpload}
              >
                {uploadContentVisible && !uploadedImage && (
                  'Drop photo here or click below!'
                )}
              </FileDrop>
              {uploadButtonVisible && (
                <button>
                  <h4>
                    Upload
                </h4>
                  <input
                    type="file"
                    placeholder='Upload'
                    onChange={handleManualUpload}
                  />
                </button>
              )}
            </div>
            {uploadError && (
              <div className='upload-error'>{uploadError}</div>
            )}
          </div>

          <div>
            <div className='upload-container desktop' style={{ backgroundImage: `url(${uploadedImage})` }}>
              <FileDrop
                onFrameDragEnter={(event) => {
                  // setUploadButtonVisibility(false)
                }}
                onFrameDragLeave={(event) => { }}
                onFrameDrop={(event) => { }}
                onDragOver={(event) => { }}
                onDragLeave={(event) => setUploadButtonVisibility(true)}
                onDrop={handleDropUpload}
              >
                {uploadContentVisible && !uploadedImage && (
                  'Drop photo here or click below!'
                )}
              </FileDrop>
              {uploadButtonVisible && (
                <button>
                  <h4>
                    Upload
                </h4>
                  <input
                    type="file"
                    placeholder='Upload'
                    onChange={handleManualUpload}
                  />
                </button>
              )}
            </div>
            {uploadError && (
              <div className='upload-error'>{uploadError}</div>
            )}
          </div>
        </div>

        <div className='calendar-action-container'>
          {(location.state && !location.state.heading) ? (
            <button
              className={`connect-button`}
              onClick={handleConnectCalendar}
            >
              {connectionLoading ? 'Loading...' : 'Connect this calendar'}
            </button>
          ) : (
              <div className='action-buttons-container'>
                <div className='action-buttons-note'>
                  <span>NOTE:</span> Generating a new Calendar URL will force all existing clients to disconnect
                </div>
                <div className='action-buttons'>
                  <button
                    className={`update-button`}
                    onClick={handleRotateURLCalendar}
                  >
                    {rotateLoading ? 'Loading...' : 'Generate new URL'}
                  </button>
                  <button
                    className={`update-button`}
                    onClick={handleUpdateCalendar}
                  >
                    {updateLoading ? 'Loading...' : 'Update calendar'}
                  </button>
                  <button
                    className={`disconnect-button`}
                    onClick={handleDisconnectCalendar}
                  >
                    {disconnectionLoading ? 'Loading...' : 'Disconnect calendar'}
                  </button>
                </div>

              </div>
            )}

          <button
            className={`cancel-button`}
            onClick={() => history.push('/user/dashboard')}
          >
            Cancel
          </button>
        </div>

        {location.state && location.state.heading && (
          <div className='connected-clients-container'>
            <h3>
              Connected Clients
          </h3>

            <table>
              <tr>
                <th>Hostname</th>
                <th>IP address</th>
                <th>First seen</th>
                <th>Last seen</th>
              </tr>
              {location.state.connections && location.state.connections.map((connection, index) => (
                <tr key={index}>
                  <td>{connection.ts}</td>
                  <td>{connection.ip}</td>
                  <td>{connection.date ? getFormattedDate('hh:mm A MMMM Do, YYYY', connection.date) : 'no data'}</td>
                  <td>{connection.last_seen ? getFormattedDate('hh:mm A MMMM Do, YYYY', connection.last_seen) : getFormattedDate('hh:mm A MMMM Do, YYYY', connection.date)}</td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default Calendars
