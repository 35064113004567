
/*global chrome*/
import React, { useEffect, } from 'react'
import { useParams, useHistory, } from "react-router-dom";
import { gapi, loadAuth2WithProps } from 'gapi-script';

import API from '../../rest/api'
import gconfig from '../../gconfig'
import CONFIG from '../../config/index'

function AuthFinish(props) {
  const history = useHistory()
  const params = useParams()

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {
    const url = window.location
    const codePosition = url.hash.indexOf('#code=') + 6
    const scopePosition = url.hash.indexOf('&scope=')

    const code = url.hash.slice(codePosition, scopePosition)

    try {
      let auth2 = await loadAuth2WithProps(gconfig);

      const currentUser = await auth2.currentUser.get()

      await API.sendGoogleAuthCode({ code, redirect_uri: `${window.origin}/redirect/auth/finish`, calendar_id: currentUser.Tt.Du })

      const payload = {
        ...currentUser.wc,
        calendar_user_id: currentUser.Tt.Du,
        code,
      }

      const e_id = localStorage.getItem('e_id')

      console.log('payload', payload, e_id)

      chrome.runtime.sendMessage(e_id, { type: "SAVE_OFFLINE_CODE", payload, }, (response) => {
        console.log('auth response', response)

        if (response === 'ok') {
          history.push('/user/login')
        }
      }); 
    } catch (error) {
      console.log('error - initialize - finish', error)
    }
  }

  return (
    <div>

    </div>
  )
}

export default AuthFinish