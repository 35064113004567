import React, { Fragment, } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect, } from "react-router-dom";
import history from './store/history';
import './App.css';

import Login from './components/Login/Login'
import DashboardLayout from './components/DashboardLayout/DashboardLayout';
import Dashboard from './components/DashboardLayout/pages/Dashboard/Dashboard';
import Calendars from './components/DashboardLayout/pages/Calendars/Calendars';
import ExtensionChecker from './components/ExtensionChecker/ExtensionChecker';
import AuthRedirect from './components/AuthRedirect/AuthRedirect'
import AuthFinish from './components/AuthFinish/AuthFinish'
import UnknownRoute from './components/UnknownRoute/UnknownRoute';

function App() {
  return (
    <Router history={history}>
      <Fragment>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/j/:id/' component={ExtensionChecker} />
          <Route exact path='/redirect/auth/start/:e_id' component={AuthRedirect} />
          <Route exact path='/redirect/auth/finish' component={AuthFinish} />
          <PrivateRoute 
            path='/user'
            layout={DashboardLayout}
          >
            <PrivateRoute exact path='/user/dashboard' component={Dashboard} />
            <PrivateRoute exact path='/user/calendars' component={Calendars} />
          </PrivateRoute>
          {/* <PrivateRoute exact path='/calendars' component={Calendars} /> */}
          <Route component={UnknownRoute} />
        </Switch>
      </Fragment>
    </Router>
  );
}

const PrivateRoute = ({ component: Component, layout: Layout, exact, children, ...rest }) => {
  const loggedIn = useSelector(state => state.user.loggedIn)

  if (!loggedIn) return <Redirect to='/' />

  if (!exact) {
    return (
      <Route {...rest} render={(props) => (
        <Layout {...props}>
          {children}
        </Layout>
      )} />
    )
  }

  return (
    <Route {...rest} render={(props) => <Component {...props} />} />
  )
}

export default App;
